import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import CheckIcon from '@mui/icons-material/Check';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat,currentYear,currentMonth} from '../../lib/functions'
import moment from 'moment';

import years from '../../years.json'
import months from '../../months.json'

import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')



const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getpayments();
    currentRouteSet(pathSpliter(location.pathname,1));
    getPayCode();
    getLoadSalaryAccounts()
  },[])

    // Init states start
 
    
    let [pay_id,pay_id_set] = useState(0)
    let [payments,paymentsSet] = useState([])
    let [fromAccs,fromAccsSet] = useState([])

    let [salaryAccs,salaryAccsSet] = useState([])
    let [selectedSalary,selectedSalarySet] = useState(null)

    
    let [paymentCart,paymentCartSet] = useState([])
    let [employees,employeesSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [payment_code,payment_code_set] = useState('');
    let [pay_ind_id,pay_ind_id_set] = useState(null);

    let [narration,narration_set] = useState('');


    let [selectedEmployee,selectedEmployeeSet] = useState(null);


    let [selectedYear,selectedYearSet] = useState({year:`${currentYear()}`});
    let [selectedMonth,selectedMonthSet] = useState({month:`${currentMonth()}`});

    let [selectedPayType,selectedPayTypeSet] = useState({pay_type:'Basic Salary'});

    let [pay_types,pay_types_set] = useState([
        {pay_type:'Basic Salary'},{pay_type:'Advance Salary'},
        ,{pay_type:'Overtime'},{pay_type:'Commission'},
        {pay_type:'Bonus'},
        {pay_type:'Deduction'},
        {pay_type:'Conveyance'},
        {pay_type:'TA'},
        {pay_type:'DA'},
        {pay_type:'MA'},
      ]);
    
    
    

    let [pay_amount,pay_amount_set] = useState('');
    let [payable_salary,payable_salary_set] = useState('');
    
    const [pay_date, pay_date_set] = useState(currentDateTime);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);

    let code_ref = useRef(null)
    let acc_from_ref = useRef(null)
    let acc_to_ref = useRef(null)
    const employee_ref= useRef(null);

    let pay_amount_ref = useRef(null)
    let payment_date_ref = useRef(null)
    let narration_ref = useRef(null)


  
  useEffect(()=>{
    getpayments()
  },[pay_date])

   
    const pay_dateSet = (date) => {
        pay_dateSet(date);
      };

  const getLoadSalaryAccounts = async() => {
    axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_name : 'salary'},{headers:{'auth-token':authInfo.token}}).then(res=>{
      salaryAccsSet(res.data)
      selectedSalarySet(res.data.length != 0 ? res.data[0]:null)
    })
    };
    
    const getpayments = async ()=>{
        await axios.post(`${API_URL}/api/get-salary-payment-with-details`,{oneDate:pay_date,page:'entry'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          paymentsSet(res.data)
      })
    }

    const loadFromAccounts = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{search:false,multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
           fromAccsSet(res.data)
        })
    };
    

    const [employee_name, employee_name_set] = useState("");
    const [account_name, account_name_set] = useState("");

    useEffect(()=>{
      loadFromAccounts()
    },[account_name])



    useEffect(()=>{
      loadEmployees()
    },[employee_name])

    const loadEmployees = async() => {
        await axios.post(`${API_URL}/api/get-employees-by-search`,{query:employee_name,search:true},{headers:{'auth-token':authInfo.token}}).then(res=>{
         employeesSet(res.data)
        })
    };

    const getPayCode = async() => {
      return  await axios.post(`${API_URL}/api/get-payment-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          payment_code_set(res.data)
        })
    };
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{

            let totalPayment = _.sumBy(paymentCart,(tran)=>parseFloat(tran.pay_amount))

            if(paymentCart.length == 0){
              swal({
                title:`payment Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else if(selectedYear == null){
                swal({
                  title:`Select Payment of Year `,
                  icon:'warning'
                });
                return false
              }else if(selectedMonth == null){
                swal({
                  title:`Select Payment of Year `,
                  icon:'warning'
                });
                return false
              }else if(selectedSalary == null){
                swal({
                  title:`Select Salary Account `,
                  icon:'warning'
                });
                return false
              }else{


                let countDeduction =  paymentCart.findIndex((pay)=>{
                  return pay.pay_type == 'Deduction' 
                })
          
                if(countDeduction != -1 &&   selectedAccountFrom != null){
                  swal({
                    title:`You can't be select From Account`,
                    icon:'warning'
                  });
                  return false
                }
          
                if(countDeduction == -1 && selectedAccountFrom == null){
                  swal({
                    title:`Select Payment From Account`,
                    icon:'warning'
                  });
                  return false
                }



            let payLoad = {
              payment:{
                pay_code:payment_code.trim(),
                salary_acc_id: selectedSalary.acc_id,
                creation_date:pay_date,
                from_acc_id: selectedAccountFrom != null ? selectedAccountFrom.acc_id : 0,
                pay_total: totalPayment, 
                narration,
                pay_id,
                month: selectedMonth.month,
                year: selectedYear.year,
                action,
                status : (authInfo.role != 'super_admin' && action == 'create' ) ? 'p': 'a'
              },
              paymentDetail:paymentCart
            }
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-employee-payment`,{  
              ...payLoad
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               pay_date_set(currentDateTime)
               paymentCartSet([])
               action_set('create')
               narration_set('')
               pay_id_set(0)
               getPayCode()
               getpayments()
               selectedAccountFromSet(null)

             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }

    }

    useEffect(()=>{
      if(selectedEmployee != null && (selectedYear != null && selectedMonth != null)){
       
          axios.post(`${API_URL}/api/get-employee-payable-amount`,{
            employeeId:selectedEmployee.employee_id,
            month:selectedMonth.month,
            year:selectedYear.year,
          },{headers:{'auth-token':authInfo.token}}).then(res=>{
            payable_salary_set(res.data[0].payable_salary)
          })
        
      }
    },[selectedEmployee])

  const removepaymentTran = (sl)=>{
    let preCopy =  [...paymentCart];
    preCopy.splice(sl, 1);
    paymentCartSet(preCopy)

  }

  const addTOPayment = ()=>{

    if(selectedPayType == null){
      swal({
        title:`Select Payment Type`,
        icon:'warning'
      });
      return false
    }else if(selectedEmployee == null){
      swal({
        title:`Select Employee`,
        icon:'warning'
      });
      return false
    }else if((pay_amount == 0 || pay_amount == '')){
      swal({
        title:`Payment Amount  cannot be 0.`,
        icon:'warning'
      });
      return false
    }else{

     let countDeduction =  paymentCart.findIndex((pay)=>{
        return pay.pay_type == 'Deduction' || pay.pay_type == 'Meal Expense' || pay.pay_type == 'Conveyance'
      })

      if(countDeduction == -1 && paymentCart.length != 0 && ( selectedPayType.pay_type == 'Deduction' || selectedPayType.pay_type == 'Meal Expense' || selectedPayType.pay_type == 'Conveyance')){
        swal({
          title:` Salary  Add  Plz on different  Voucher`,
          icon:'warning'
        });
        return false
      }

      if(countDeduction > -1 && (selectedPayType.pay_type != 'Deduction' || selectedPayType.pay_type != 'Meal Expense' || selectedPayType.pay_type != 'Conveyance')){
        swal({
          title:` Can't be add on this  Voucher`,
          icon:'warning'
        });
        return false
      }

      let tran = {
        to_acc_id : selectedEmployee.employee_id,
        acc_name : selectedEmployee.employee_name,
        pay_type : selectedPayType.pay_type,
        pay_amount : pay_amount =='' ? 0 :pay_amount
      }

      if(pay_ind_id == null){
        paymentCartSet([...paymentCart,tran])
      }else{
        let preCopy =  [...paymentCart];
        preCopy[pay_ind_id] = tran
        paymentCartSet(preCopy)
      }


      pay_ind_id_set(null)
      // selectedPayTypeSet({pay_type:'Basic Salary'})
      selectedEmployeeSet(null)

      pay_amount_set('')
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

      {
        authInfo.role =='super_admin' && props.rowData[1] == 'p'?(<>
                <CheckIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>approveAction(props.rowData)}/>
        </>):''
      }

{
    authInfo.role !='user'?(  
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>paymentEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>paymentDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const paymentEdit = (row,index)=>{
      let payment =  payments.filter((payment)=>payment.pay_id == row[0])
      payment = payment[0]
      paymentCartSet(payment.details)
      selectedAccountFromSet({acc_name:payment.acc_name,acc_id:payment.from_acc_id})

      pay_id_set(payment.pay_id)
      action_set('update')
      payment_code_set(payment.pay_code)
      pay_date_set(payment.creation_date)
      narration_set(payment.narration)

      selectedSalarySet({acc_name : payment.salary_acc_name,acc_id:payment.salary_acc_id})

      selectedYearSet({year : payment.year})
      selectedMonthSet({month : payment.month})
     
    }


    const editpaymentTran = (row,sl)=>{
      pay_ind_id_set(sl)
      selectedPayTypeSet({pay_type:row.pay_type})
      selectedEmployeeSet({employee_name:row.acc_name,employee_id : row.to_acc_id})
      pay_amount_set(row.pay_amount)
    }


    const paymentDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let payment =  payments.filter((payment)=>payment.pay_id == row[0])
          await axios.post(`${API_URL}/api/delete-salary-payment`,{pay_id:payment[0].pay_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getpayments();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }

          const approveAction = async (row)=>{
            swal({
              title:'Are you sure approve this?',
              icon:'warning',
              buttons:true
            }).then(async(yes)=>{
              if(yes){
                let payment =  payments.filter((payment)=>payment.pay_id == row[0])
                await axios.post(`${API_URL}/api/approve-salary-payment`,{pay_id:payment[0].pay_id},
                {headers:{'auth-token':authInfo.token}}).then(res=>{
                        getpayments();
                        swal({
                          title: res.data.msg,
                          icon:'success'
                        })
                        
                      })
              }else{
                return false
              }
            })
            
      
                }


                const DetailOptions = (props)=>{
                  return(<div style={{textAlign:'left'}}> 
            
            {
              
               
            
            
            
                <table style={{width:'100%'}}>
                  <tr>
                    <th></th>
                  </tr>
            
            
            
                  {
                  props.rowData[2].map((item)=>(<>
                  <tr>
                    <td>{item.acc_name}</td>
                  </tr>
                    
                  </>))
                }
                  
                </table>
              
                
             
             }
                  
                  </div>)
                
                }

                
   
    const columns = [
      {name: "pay_id",options: { display: 'excluded' }},
      {name: "status",options: { display: 'excluded' }},
      {name: "details",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "pay_code",label: "Payment Code",options: {filter: true,sort: true}},

      {name:"Employee",options: {filter: true,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <DetailOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }},

      {name: "creation_date",label: "Payment Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[6]).format(dateFormat) }</p>) 
        }
      }},
      {name: "acc_name",label: "Pay From Acc",options: {filter: true,sort: true}},
      {name: "month",label: "Pay For  Month",options: {filter: true,sort: true}},
      {name: "year",label: "Year",options: {filter: true,sort: true}},
      {name: "pay_total",label: "Pay Total",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_full_name",label: "Payment By",options: {filter: true,sort: true}},

      {name:"Staus",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <> {tableMeta.rowData[1] == 'p' ? 'Pending':'Done'} </> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Salary Payment Entry </h2>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
 

<Grid item xs={12} sm={12}>

<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={pay_types} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.pay_type}
              value={selectedPayType}
              onChange={(event,selectedObj)=>{
                selectedPayTypeSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      employee_ref.current.focus()
                    }
                  }}
                  label="Payment Type"
                  variant="outlined"
                
                />
              )}
          />
</Grid>
     


<Grid item xs={12} sm={12} style={{marginTop:'10px'}}>




<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={employees} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.employee_name}
              value={selectedEmployee}
              onChange={(event,selectedObj)=>{
                selectedEmployeeSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={employee_ref} 
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    pay_amount_ref.current.focus()
                  }
                }}
                onChange={(e)=>employee_name_set(e.target.value)}
                  {...params}
                  label="Payment To Employee"
                  variant="outlined"
                
                />
              )}
          />

      

</Grid>

 

{
  selectedEmployee != null && (selectedYear != null && selectedMonth != null) ?(<>
  <Grid item xs={12} sm={12}>

<span style={{color:'green',fontWeight:'bold'}}>Payable Salary is :  { format(parseFloat(payable_salary).toFixed(2)) } {authInfo.currency}</span>
</Grid>
  </>):''
}


        <Grid item xs={12} sm={12} style={{marginTop:'10px'}}> 
        <TextField type="number"  
          inputRef={pay_amount_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            addTOPayment()
           }
         }} 
         autoComplete='off' className={classes.fullWidth} 
        label="Payment Amount" name="pay_amount" value={pay_amount} 
        variant="outlined" size="small" onChange={(e)=>{

                    pay_amount_set(e.target.value)
                
           
            }} />

<span>Press Enter Key to Payment Cart </span>

        </Grid>
        <br/>

        


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOPayment()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To payment</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8}>

        <Grid item xs={12} sm={12}>


            <Grid item xs={12} sm={5} style={{float:'left',width: '100%'}}>
      
            <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={fromAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountFrom}
              onChange={(event,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                onChange={(e)=>account_name_set(e.target.value)}
                  {...params}
                  label="Payment From Account"
                  variant="outlined"
                
                />
              )}
          />

            </Grid>


            <Grid item xs={12} sm={3} style={{float:'right',marginTop: '-8px',marginLeft:'5px'}}> 
      
            <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Entry Date" 
                  inputFormat={dateTimeFormat}
                  value={pay_date}
                  onChange={(e)=>pay_date_set(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                
              </Stack>
              </LocalizationProvider>


        </Grid>


        <Grid item xs={12} sm={3} style={{float:'right'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        inputRef={code_ref}
        onKeyDown={event => {
            if (event.key === "Enter") {
              payment_date_ref.current.focus()
            }
          }} 
          disabled
        label="payment Code" name="payment_code" value={payment_code} 
        variant="outlined" size="small" onChange={(e)=>payment_code_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={5} style={{float:'left',width: '100%',marginTop:'10px'}}>
      
      <Autocomplete
        autoHighlight={true}
        openOnFocus={true}
        style={{ width: '100%' }}
        options={years} 
        size="small"
        classes={{
          option: classes.option,
        }}
        getOptionLabel={(option) => option.year}
        value={selectedYear}
        onChange={(event,selectedObj)=>{
          selectedYearSet(selectedObj)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Pay of  Year  "
            variant="outlined"
          
          />
        )}
    />

      </Grid>  

      <Grid item xs={12} sm={2} ></Grid>

      <Grid item xs={12} sm={3} style={{float:'left',width: '100%',marginTop:'10px',marginLeft:'5px'}}>
      
      <Autocomplete
      
        autoHighlight={true}
        openOnFocus={true}
        style={{ width: '100%' }}
        options={months} 
        size="small"
        classes={{
          option: classes.option,
        }}
        getOptionLabel={(option) => option.month}
        value={selectedMonth}
        onChange={(event,selectedObj)=>{
          selectedMonthSet(selectedObj)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Pay of  Month  "
            variant="outlined"
          
          />
        )}
    />

      </Grid>  

      <Grid item xs={12} sm={3} style={{float:'left',width: '100%',marginTop:'10px',marginLeft:'5px'}}>
        <Autocomplete
              disabled={true}
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={salaryAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedSalary}
              
              onChange={(event,selectedObj)=>{
                selectedSalarySet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a salary Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

        
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" style={{marginTop: '20px'}}>
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Employee Acc.</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Pay Type</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Pay Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                paymentCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == pay_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.acc_name}</TableCell>
                  <TableCell align="left">{acc.pay_type}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.pay_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editpaymentTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removepaymentTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                paymentCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={3} style={{fontWeight:'bold'}}>Total payment Amount : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(paymentCart,(tran)=>parseFloat(tran.pay_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize  
             ref={narration_ref}
            
               
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>


      


     



        

      
  
      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Salary Payment Entry List"}
      data={payments}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);